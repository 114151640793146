<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Cupom - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'store-coupons'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-store-coupon :store_coupons="store_coupons" :update="true"></form-store-coupon>
</span>
</template>

<script>
import FormStoreCouponComponent from "./partial/FormStoreCouponComponent";

export default {
    name: "EditStoreCouponComponent",
    created() {
        this.loadStoreCoupon()
    },
    props: {
        stor_co_id: {
            require: true
        }
    },
    data() {
        return {
            store_coupons: {},
            langs: true
        }
    },
    methods: {
        loadStoreCoupon() {
            this.$store.dispatch('loadStoreCoupon', this.stor_co_id)
                .then(response => {
                    this.store_coupons = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formStoreCoupon: FormStoreCouponComponent
    }
};
</script>

<style scoped>

</style>
