<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-select v-model="store_coupons.stor_co_tipo_de_cupom" :items="cuponsType" item-text="name" item-value="value" label="Selecione um tipo..." :rules="rules.store_couponsMeasureRules" :disabled="(this.update)" required outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="store_coupons.stor_co_nome" :rules="rules.stor_co_nomeRules" label="Nome" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="store_coupons.stor_co_codigo" @blur="removeEspacoUpper()" :disabled="(store_coupons.stor_co_tipo_de_cupom !== 'PERSONALIZADO')" label="Códígo do Cupom" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="store_coupons.stor_co_data_inicio" :rules="rules.stor_co_data_inicioRules" label="Data de Início" v-mask="'##/##/####'" outlined required>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="store_coupons.stor_co_data_fim" :rules="rules.stor_co_data_fimRules" label="Data de Término" v-mask="'##/##/####'" outlined required>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="store_coupons.stor_co_quantidade" :rules="rules.stor_co_quantidadeRules" label="Quantidade" :disabled="(this.update)" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-radio-group v-model="store_coupons.stor_co_tipo_desconto" :mandatory="false" row>
                        <v-radio color="lime" label="Porcentagem (%)" value="P"></v-radio>
                        <v-radio color="lime" label="Reais (R$)" value="R"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <div class="input-v-money">
                        <label for="">Valor</label>
                        <money v-model="store_coupons.stor_co_valor_desconto" :rules="rules.stor_co_valor_descontoRules" v-bind="money">
                        </money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <div class="input-v-money">
                        <label for="">Valor acima de</label>
                        <money v-model="store_coupons.stor_co_valor_acima_de" v-bind="money"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-switch color="lime" v-model="store_coupons.stor_co_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon class="mdi mdi-check mr-2"></v-icon>{{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    Money
} from 'v-money'
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormStoreCouponComponent",
    props: {
        store_coupons: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    stor_co_id: '',
                    product_id: '',
                    stor_co_tipo_de_cupom: '',
                    stor_co_nome: '',
                    stor_co_codigo: '',
                    stor_co_tipo_desconto: '',
                    stor_co_valor_desconto: '',
                    stor_co_data_inicio: '',
                    stor_co_data_fim: '',
                    stor_co_quantidade: '0',
                    stor_co_usados: '0',
                    stor_co_valor_acima_de: '',
                    stor_co_status: '',
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {

    },
    computed: {

    },
    watch: {

    },

    data() {
        return {
            cuponsType: [{
                    name: 'ALEATÓRIO',
                    value: 'ALEATORIO'
                },
                {
                    name: 'PERSONALIZADO',
                    value: 'PERSONALIZADO'
                },
            ],
            valid: true,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            rules: {
                stor_co_nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 150) || 'Nome precisa ter até 200 Caracteres'
                ],
                stor_co_valor_descontoRules: [
                    v => !!v || 'Campo Valor é obrigatório'
                ],
                stor_co_data_inicioRules: [
                    v => !!v || 'Campo Data de Início é obrigatório'
                ],
                stor_co_data_fimRules: [
                    v => !!v || 'Campo Data Final é obrigatório'
                ],
                stor_co_quantidadeRules: [
                    v => !!v || 'Campo Quantidade é obrigatório'
                ]
            },
            base_url: URL_BASE
        }
    },

    methods: {
        log(msg) {
            console.log(msg)
        },
        compDatas() {
            let partesDataI = this.store_coupons.stor_co_data_inicio.split('/')
            let partesDataF = this.store_coupons.stor_co_data_fim.split("/")
            let datai = new Date(partesDataI[2], partesDataI[1] - 1, partesDataI[0]);
            let dataf = new Date(partesDataF[2], partesDataF[1] - 1, partesDataF[0]);

            let hoje = new Date()
            hoje.setHours(0)
            hoje.setMinutes(0)
            hoje.setSeconds(0)
            hoje.setMilliseconds(0)

            if (datai.getTime() < hoje.getTime()) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Atenção',
                    text: 'Data Inicial deve ser igual ou maior que hoje',
                    showConfirmButton: true,
                    timer: 6000
                });
                return false
            }

            if (dataf.getTime() < datai.getTime()) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Atenção',
                    text: 'Data Final deve ser maior que a data Inicial',
                    showConfirmButton: true,
                    timer: 6000
                });
                return false
            }

            return true
        },
       /* compDataIDataF() {
            var partesDataI = this.store_coupons.stor_co_data_inicio.split("/")
            var partesDataF = this.store_coupons.stor_co_data_fim.split("/")
            let hoje = new Date()

            var dataInicial = new Date(partesDataI[2], partesDataI[1] - 1, partesDataI[0]);
            var dataFinal = new Date(partesDataF[2], partesDataF[1] - 1, partesDataF[0]);
            if (dataInicial > dataFinal) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Atenção',
                    text: 'Data Final deve ser maior do que a Data Inicial',
                    showConfirmButton: true,
                    timer: 6000
                });
                return false
            }
            return true
        },*/
        removeEspacoUpper() {
            let t = this.store_coupons.stor_co_codigo.toUpperCase()
            this.store_coupons.stor_co_codigo = t.replace(/ /g, "")
        },
        onSubmit() {

            if (this.store_coupons.stor_co_tipo_de_cupom === 'PERSONALIZADO' && this.store_coupons.stor_co_codigo === '') {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Atenção',
                    text: 'Campo Código do Cupom precisa ser preenchido',
                    showConfirmButton: true,
                    timer: 6000
                });
                return
            }

            if (this.compDatas()) {
                if (this.$refs.form.validate()) {
                    const action = this.update ? 'updateStoreCoupon' : 'storeStoreCoupon';
                    const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                    this.$store.dispatch(action, this.store_coupons)
                        .then(() => {
                            if (!this.update) {
                                this.reset();
                            } else {
                                this.$router.push({
                                    name: 'store-coupons'
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Erro',
                                text: 'Algo deu errado',
                                showConfirmButton: true,
                                timer: 6000
                            });
                            console.log(error)
                            //this.errors = error.response.data.errors
                        })
                } else {
                    this.$swal({
                        position: "center",
                        icon: "warning",
                        title: 'Alguns campos requerem sua atenção',
                        text: 'Verifique os campos em vermelho',
                        showConfirmButton: true,
                        timer: 6000
                    });
                }
            }

        },
        reset() {
            this.$refs.form.reset()
            this.store_coupons.stor_co_quantidade = '0'
            this.store_coupons.stor_co_usados = '0'
            this.store_coupons.stor_co_valor_acima_de = '0.00'
            this.store_coupons.stor_co_valor_desconto = '0.00'
        },
    },
    components: {
        Money
    },
};
</script>

<style lang="scss" scoped>
.input-v-money {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    border-radius: 4px;
    padding: 6px 12px;
}

.input-v-money>label {
    color: rgba(255, 255, 255, 0.3);
    font-size: .8rem;
}

.input-v-money>input {
    color: #FFF;
}

:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}

.ProseMirror {
    border: none;
    outline: 0;
}

.ProseMirror,
.ProseMirror-focused,
.ProseMirror-focused:focus,
textarea:focus,
input:focus,
select:focus {
    border: none !important;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
</style>
